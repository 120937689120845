<template>
  <div class="home">
    <GradesStudent />
  </div>
</template>

<script>
// @ is an alias to /src
import GradesStudent from '@/components/grade/GradesStudent'

export default {
  name: 'GradesView',
  components: {
      GradesStudent
  }
}
</script>