<template>
  <div class="section">
    <h1 class="subtitle">{{t('componentNames.studentGrades')}}</h1>
    <b-table :data="grades">
      <b-table-column field="exam" :label="t('exam.header')" v-slot="props">{{props.row.exam}}</b-table-column>
      <b-table-column field="grades" :label="t('common.grades')" v-slot="props">
        <span v-for="item in props.row.grades" :key="item.id" class="tag mr-2">
          {{item.date}} :
          <strong>{{item.grade}}</strong>
        </span>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from '@/mixins/commons'

export default {
  name: 'GradesStudent',
  mixins: [CommonHelpers],
  components: {
  },

  props: {},

  data: function () {
    return {
      grades: [
        { 'exam': 'Diagnoza 1', 'grades': [{ 'id': 0, 'date': '2020-12-22', 'grade': 5.0 }] },
        { 'exam': 'Diagnoza 2', 'grades': [{ 'id': 0, 'date': '2020-12-22', 'grade': 2.0 }, { 'id': 1, 'date': '2020-12-23', 'grade': 5.0 }] }
      ],
      columns: [
        {
          field: "exam",
          label: "Egzamin",
        },
        {
          field: "grades",
          label: "Wyniki",
        }
      ]
    }
  },

  mounted() {
  },

  watch: {
  },

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped>
</style>